//TFG = 141 * Math.min(Creatinina sérica/kappa1),alpha * Math.max(Creatinina sérica/kappa1),-1.209 * 0.993Edad * Sexo * Raza

const factors = {
    female: {
        gender: 1.018,
        alpha : -0.329,
        kappa : 0.7,
        race : {
            white_and_others : 1,
            black : 1.159
        }
    },
    male: {
        gender: 1,
        alpha : -0.411,
        kappa : 0.9,
        race : {
            white_and_others : 1,
            black : 1.159
        }
    }
}

//gender [male, female];
// race [white_and_others, black]

const CKD_EPI = (gender, race, age, serum_creatitine) => {

    const _factors = factors[gender];

    const gfr = 141 * Math.pow(Math.min(serum_creatitine/_factors.kappa),_factors.alpha) 
    * Math.pow(Math.max(serum_creatitine/_factors.kappa),-1.209) 
    * Math.pow(0.993,age) 
    * _factors.gender 
    * _factors.race[race]
    
    return gfr
}

const cockroftGault = (gender, age, weight, serum_creatitine) => {
    const factors = {
        male: 72,
        female: 0.85
    };
   // console.log(parseFloat(weight));
   
   const cr_male= Number((((140-parseInt(age))*parseFloat(weight))/(serum_creatitine*factors['male'])).toFixed(2));
   if(gender=='male'){
    return cr_male;
   }else{
    return cr_male*factors['female'];
   }
}

const schwartz = (heigth, serum_creatitine, poblational_group) => {
    const factors = {
        'low_weight_newborn' : 0.33,
        'newborn_infant' : 0.45,
        'boys_and_girls' : 0.55,
        'man' : 0.70,
        'woman' : 0.55
    };
    const K = factors[poblational_group]
    return Number(((K*heigth)/serum_creatitine).toFixed(2))
}

const glomerularFiltracionRate = (age) => {
    const functions = {
        cockroftGault : cockroftGault,
        schwartz: schwartz
    }

    if(age >= 18){
        return functions.cockroftGault
    }else{
        return functions.schwartz
    }
}

//glomerularFiltracionRate('male', 'black', 28, 0.90);

export {
    CKD_EPI,
    cockroftGault,
    schwartz,
    glomerularFiltracionRate
}
