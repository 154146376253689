<template>
    <main>
        <page-header-compact>
            Imprimir Planilla de Trabajo
        </page-header-compact>
        <div class="container-fluid px-4">
            <div class="card mt-4">
                <div class="card-header">
                    <div class="row">
                        <div class="col-4 d-flex justify-content-between">
                            <label class="font-weight-bolder text-black mx-2">Fecha</label>
                            <input type="date" class="form-control form-control-sm" v-model="workDate">
                        </div>
                    </div>
                </div>
                <div class="card-body">                    
                    <data-grid :data="pagination" @changePage="changePage($event)"
                        @perPageChange="perPageChange($event)" :searchBox=false>
                        <template #grid>
                            <div class="card shadow-none mb-2" v-for="(item, i) in pagination.data" :key="`d_${i}`">
                                <div class="card-body d-flex">
                                    <div>
                                        <span class="font-weight-bolder"> {{ item.nombre }}</span>
                                    </div>
                                    <div class="justify-content-end">
                                        <button class="btn btn-light text-primary btn-xs shadow-xs mx-1" type="button"
                                        @click="printWorksheet(item.id)">
                                            <i class="fa fa-file-pdf fa-fw text-danger"></i> Imprimir
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </data-grid>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import PageHeaderCompact from "../../../../../components/layouts/content/page-header-compact";
import DataGrid from "../../../../../components/common/utilities/DataGrid/DataGrid";
import Toast from '../../../../../components/common/utilities/toast';
import AreaLaboratorioService from '../../../../../services/AreaLaboratorioService';
import dayjs from "dayjs";
import ordenTrabajoService from "@/services/ordenTrabajoService";
import {downloadFile} from "@/utils/utils";

export default {
    components: {
        PageHeaderCompact,DataGrid
    },
    data() {
        return {
            areaslaboratorio: {},
            pagination: {
                data: [],
                current_page: 1,
                page: 1,
                per_page: 10,
                search: '',
                total: '',
            },
            workDate: dayjs().format("YYYY-MM-DD"),
        }
    },
    methods: {
        async getAreasLaboratorio() {
            try {
                this.LoaderSpinnerShow();
                const response = (await AreaLaboratorioService.list(this.pagination.search, this.pagination))?.data;
                this.areaslaboratorio = response.data;
                console.log(response.data);
                this.pagination.data = response.data;
                this.pagination.current_page = response.current_page;
                this.pagination.total = response.total;
                this.LoaderSpinnerHide();

            } catch (e) {
                console.error(e);
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        changePage(page) {
            this.pagination.page = page;
            this.getAreasLaboratorio();
        },
        perPageChange(per_page) {
            this.pagination.per_page = per_page;
            this.pagination.page = 1;
            this.getAreasLaboratorio();
        },
        search(query) {
            this.pagination.search = query;
            this.pagination.page = 1;
            this.getAreasLaboratorio();
        },
        async printWorksheet(areaId) {
          try {
            this.LoaderSpinnerShow();
              const {data} = await ordenTrabajoService.printWorksheet({
                'areaId' : areaId,
                'workDate' : this.workDate
              });

              downloadFile(data, 'application/pdf', 'filename.pdf');
            this.LoaderSpinnerHide();
            await Toast.fire({
              icon: 'success',
              title: 'Planilla generada con exito'
            });
          }catch (e) {
            this.LoaderSpinnerHide();
            console.error(e);
            Toast.fire({
              icon: 'error',
              title: 'Ocurrio un error al procesar la solicitud'
            });
          }
        }

    },
    async created() {
        await this.getAreasLaboratorio();
    }
}
</script>
